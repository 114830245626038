import { useEffect, useRef, useState } from 'react';
import SaathiAppIcon from '../../assets/icons/SaathiAppIcon.png';
import arrowRightBlueSVG from '../../assets/icons/arrowRightBlue.svg';
import boyGreyBeanie from '../../assets/icons/boyGreyBeanie.png';
import boyThumbsUp from '../../assets/icons/boyThumbsUp.png';
import getAppIcon from '../../assets/icons/getAppIcon.svg';
import girlBlackBeanie from '../../assets/icons/girlBlackBeanie.png';
import merchantLogos from '../../assets/icons/merchantLogos.png';
import purpleRupeeCoin from '../../assets/icons/purpleRupeeCoin.png';
import rupeeShadow from '../../assets/icons/rupeeShadow.png';
import { ReactComponent as SafeSVG } from '../../assets/icons/safe.svg';
import { ReactComponent as SecureSVG } from '../../assets/icons/secure.svg';
import suryodayLogo from '../../assets/icons/suryodayLogo.svg';
import { ReactComponent as TrustedSVG } from '../../assets/icons/trusted.svg';
import phone from '../../assets/images/phone.png';
import rewardsBackground from '../../assets/images/rewardsBackground.jpg';
import video from '../../assets/videos/flow.mp4';
import './LandingPage.scss';
// import DownloadSaathiFooter from '../../sharedComponents/DownloadSaathiFooter/DownloadSaathiFooter';
import ReactPixel from 'react-facebook-pixel';
import { HashLink } from 'react-router-hash-link';
import { ReactComponent as AnnotationCreateGoal } from '../../assets/icons/create-goal.svg';
import { ReactComponent as AnnotationGoalAmount } from '../../assets/icons/goal-amount.svg';
import { ReactComponent as AnnotationLinkMerchant } from '../../assets/icons/link-merchant.svg';
import mobileBrandPicturesBottomPNG from '../../assets/icons/mobileBrandPicturesBottom.png';
import mobileBrandPicturesTopPNG from '../../assets/icons/mobileBrandPicturesTop.png';
import { ReactComponent as AnnotationSpend } from '../../assets/icons/spend.svg';
import EarnBigRewardsBackground from '../../assets/images/EarnBigRewardsBackground.png';
import {
  AppleAppStoreButton,
  GooglePlayStoreButton,
} from '../../sharedComponents/AppStoreButtons/AppStoreButtons';
import { getMobileOperatingSystem } from '../../utils';
import ProductFeedbacks from './ProductFeedbacks/ProductFeedbacks';
import SavingsClubBanner from './SavingsClubBanner/SavingsClubBanner';

export default function Landing({
  deviceType,
  projectLiveBoolean,
  setShowRegOrDLModalBool,
  setShowContactUsModalBool,
}) {
  //const navigate = useNavigate();
  console.log('setShowContactUsModalBool ' + setShowContactUsModalBool);
  const headerRef = useRef(null);
  const [headerVisible, setHeaderVisible] = useState(false);
  const topRate = '7.75';
  const topRateInt = '7';
  const topRateDec = '75';

  useEffect(() => {
    if (!headerRef.current) return;
    const el = headerRef.current;

    const io = new IntersectionObserver(
      (entries) => {
        if (entries?.[0]?.isIntersecting) setHeaderVisible(true);
      },
      {
        threshold: 1,
      },
    );

    io.observe(el);

    return () => {
      io.disconnect();
    };
  }, [headerRef]);

  ReactPixel.pageView();

  return (
    <div id='LandingPage'>
      {/* FIRST SECTION (with gradient background): */}
      <div className='topGradientContainer'>
        <div className='radialPinkBlueGradient' />
        <div className='whiteningLinearGradient' />
        <div className='introductionSection'>
          <div className='leftSide'>
            <img src={SaathiAppIcon} id='SaathiAppIcon' alt='Saathi app icon' />
            <h1>Get rewarded when you save for your goals</h1>

            <p>
              High-interest digital savings account + awesome rewards so you can save for the things
              you love.
            </p>

            {/* projectLiveBoolean ? (
              deviceType === 'desktop' ? (
                <a id='getTheAppButton' href='/register'>
                  <span>Get Started</span>
                </a>
              ) : //If project is live but they are on mobile then show them app store link relative to their device:
              getMobileOperatingSystem() === 'iOS' ? (
                <AppleAppStoreButton />
              ) : (
                <GooglePlayStoreButton />
              )
            ) : (
              //If project isnt live then just show them the 'Get early access' button
              <div id='getTheAppButton' onClick={() => setShowRegOrDLModalBool(true)}>
                <span>Early Access</span>
                <img src={getAppIcon} alt='Get early access to the Saathi app' />
              </div>
            )*/}
            
            <a id='getTheAppButton' href='/register'>
              <span>Get Started</span>
            </a>

            <div id='suryodayLabel'>
              <img src={suryodayLogo} alt='Suryoday Bank logo' />
              <span>Your money is secured by Suryoday Bank.</span>
            </div>
          </div>

          <div className='rightSide'>
            <div className='device'>
              <AnnotationCreateGoal />
              <AnnotationGoalAmount />
              <AnnotationLinkMerchant />
              <AnnotationSpend />
              <video autoPlay muted playsInline src={video}></video>
              <img src={phone} alt='Saathi app explainer video' />
            </div>
          </div>
        </div>

        <div id='moneyIsntGoodSection' ref={headerRef} className={headerVisible && 'play'}>
          <h5>OUR MANIFESTO</h5>
          <h1>
            <span className='one'>Money isn't good. </span>
            <span className='two'>Money isn't bad. </span>
            <span className='three'>When you don't have it, life is tough. </span>
            <span className='four'>When you do have it, it can be hard to manage. </span>
            <span className='five'>Our relationship with money is what really counts.</span>
          </h1>
        </div>
      </div>

      {/* SECOND SECTION (save better with Saathi): */}
      <div id='saveBetterWithSaathiContainer'>
        <h1>Save better with Saathi</h1>
        <p className='headerCaption'>
          Industry-leading interest rates, incredible rewards with your favourite brands and
          collaborative saving, to name a few.
        </p>
        <div id='gridContainer'>
          <div id='leftPortion'>
            <img
              id='EarnBigRewardsBackground'
              src={EarnBigRewardsBackground}
              alt='Earn big rewards explainer'
            />
            <div className='textSection'>
              <h1 className='tabHeader'>Earn big rewards</h1>
              <p className='tabDescription'>
                Get up to an additional 10% off with some amazing brands, saving you thousands on
                everything from international trips to helpful home-cleaning robots!
              </p>
            </div>

            <HashLink id='viewOurMerchantsButton' to={`/ForBusinesses#brandsSection`}>
              <span>View our merchants</span>
              <img src={arrowRightBlueSVG} alt='Right facing arrows' />
            </HashLink>
          </div>

          <div id='rightPortion'>
            <div className='topSquare'>
              <div className='textSection'>
                <h1 id='percentFigure'>
                  {topRateInt}
                  <span>
                    .{topRateDec}%
                    <br />
                    earnings
                  </span>
                </h1>
                <p className='tabDescription'>
                  Earn up to {topRate}% per annum on your savings account with a zero balance
                  facility, plus a virtual card for spending with no fees.
                </p>
              </div>
              <img src={purpleRupeeCoin} id='purpleRupeeCoin' alt='Purple rupee coin' />
              <img src={rupeeShadow} id='rupeeShadow' alt='Purple rupee coin shadow' />
            </div>

            <div className='bottomSquare'>
              <div className='textSection'>
                <h1 className='tabHeader'>
                  Saving is
                  <br />
                  better. Together
                </h1>
                <p className='tabDescription'>
                  Invite the people you know to save together for the things that matter to you all,
                  such as holidays or celebrations.
                </p>
              </div>

              <img src={girlBlackBeanie} id='girlBlackBeanie' alt='Girl in black beanie' />
              <img src={boyGreyBeanie} id='boyGreyBeanie' alt='Boy in grey beanie' />
              <img src={boyThumbsUp} id='boyThumbsUp' alt='Boy giving thumbs up' />
            </div>
          </div>

          <div id='fullWidth'>
            <div className='leftPortion'>
              <img src={suryodayLogo} alt='Suryoday bank logo' />
              <p>Your money is secured by Suryoday Bank.</p>
            </div>

            <div className='rightPortion'>
              <div id='trusted'>
                <TrustedSVG />
                <h4>Trusted</h4>
                <p>We host your account in an RBI-licensed bank</p>
              </div>
              <div id='secure'>
                <SecureSVG />
                <h4>Secure</h4>
                <p>
                  Bank-grade security,
                  <br />
                  ISO & PCI compliance
                </p>
              </div>
              <div id='safe'>
                <SafeSVG />
                <h4>Safe</h4>
                <p>Your money is insured up to ₹5,00,000</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* THIRD SECTION (rewards for every occasion): */}
      <div id='rewardsGalleryContainer'>
        {/* DESKTOP ONLY: */}
        <img src={rewardsBackground} id='rewardsBackground' alt='Rewards background' />
        {/* MOBILE ONLY: */}
        <div id='mobileBrandPicturesTopContainer'>
          <img src={mobileBrandPicturesTopPNG} alt='Mobile brands container' />
        </div>
        <div id='centralTab'>
          <img src={merchantLogos} id='merchantLogos' alt='Merchant logos' />
          <h3>Rewards for every occasion</h3>
          <p>
            We work with the best in the business to bring you some amazing and relevant rewards and
            cashback for saving and shopping smarter.
          </p>
        </div>
        {/* MOBILE ONLY: */}
        <div id='mobileBrandPicturesBottomContainer'>
          <img src={mobileBrandPicturesBottomPNG} alt='Mobile brands bottom container' />
        </div>
      </div>

      {/* FOURTH SECTION (kind of credit we like): */}
      <div id='KindOfCredit'>
        <ProductFeedbacks />

        <SavingsClubBanner setShowRegOrDLModalBool={setShowRegOrDLModalBool} />
      </div>

      {/* FIFTH SECTION (download Saathi from App store): */}
      {/* <DownloadSaathiFooter setShowRegOrDLModalBool={setShowRegOrDLModalBool} /> */}
    </div>
  );
}
