import './ManifestoAnimation.scss';
import { useEffect, useRef } from 'react';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ManifestoFlat from '../ManifestoScroll/ManifestoFlat.svg';

// const lerp = (a, b, t) => a + t * (b - a);
gsap.registerPlugin(ScrollTrigger);

export default function ManifestoAnimation() {
  const containerRef = useRef(null);
  const svgRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const svg = svgRef.current;
    let factor = 1;
    if(window.innerWidth<=500) {
      factor = 1;
    } else {
      factor = 1.35
    }

    const scrollWidth = svg.scrollWidth - (window.innerWidth*factor); // Calculate how much to scroll

    gsap.to(svg, {
      x: -scrollWidth, // Moves the SVG left as user scrolls down
      ease: "none",
      scrollTrigger: {
        trigger: container,
        start: "top top",
        end: () => `+=${scrollWidth}`, // Make the animation last the entire horizontal scroll
        scrub: 1, // Smooth scrolling effect
        pin: true, // Keeps the section fixed while scrolling horizontally
        anticipatePin: 1,
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill()); // Clean up on unmount
    };
  }, []);
  return (
    <div className="manifesto-scroll-container" ref={containerRef}>
      <img src={ManifestoFlat} ref={svgRef} alt="Manifesto" className="manifesto-svg" />
    </div>
  );
}
