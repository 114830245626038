export default [
  {
    name: 'Zahoor',
    dept: 'Founder',
  },
  {
    name: 'Andrew',
    dept: 'Founder',
  },
  {
    name: 'Shazia',
    dept: 'Founder',
  },
  {
    name: 'Alex',
    dept: 'Product',
  },
  {
    name: 'Nam',
    dept: 'Product',
  },
  {
    name: 'Divina',
    dept: 'Product',
  },
  {
    name: 'Aman',
    dept: 'Product',
  },
  {
    name: 'Rishabh',
    dept: 'Technology',
  },
  {
    name: 'Bhakti',
    dept: 'Technology',
  },
  {
    name: 'Jay',
    dept: 'Technology',
  },
  {
    name: 'Vandhana',
    dept: 'Business',
  },
  {
    name: 'Anirudh',
    dept: 'Business',
  },
  {
    name: 'Harshal',
    dept: 'Business',
  },
  {
    name: 'Keerthi',
    dept: 'Business',
  },
  // {
  //   name: "Krishanu",
  //   dept: "Data",
  // },
  {
    name: 'Nigel',
    dept: 'Brand',
  },
  {
    name: 'Richa',
    dept: 'Marketing',
  },
  {
    name: 'Shefali',
    dept: 'Marketing',
  },
  {
    name: 'Sudhanva',
    dept: 'Marketing',
  },
  {
    name: 'Wrisha',
    dept: 'Marketing',
  },
];
